<template>
  <div class=container>
    <div class="row">
      <div class="col-md-12">
        <div class="home-content">
          <h1 class="mt-3">RTDBox Analyses</h1>
          <div class="text-left">
            <p>RTDBox is a graphical user interface for construction of high quality Reference Transcript Data (RTD) with guidance of a reference genome by using high-throughput Illumina short-read and PacBio long-read data.</p>
            <p>The RTDBox is designed to:
            <ul>
              <li>Provide an automated pipeline for the entire transcriptome construction process from raw sequenced reads to the final RTD with evaluation metrics. It is very easy to use by scientists with basic bioinformatics skills or bioinformaticians with little experience in transcriptomics.</li>
              <li>Integrate the transcript assemblies from both short-read and long-read sequencing data. It can be used flexibly depending on the data available.</li>
              <li>Evaluate the transcript length, strand, splice junction and splice phase to filter redundant, fragmented and chimeric transcripts, and transcripts with poorly supported splice junctions.</li>
              <li>Create reports to summarise the intermediate and final results of the analysis.</li>
              <li>Generate databases of splice junctions, splice phases and TSS and polyadenylation.</li>
            </ul>
            </p>
          </div>
          <img src="images/pipelines.png"/>
        </div>
        <p class="text-left" v-if="token != null"><a href="#/shortread/home">Visit the Short Read Pipeline Homepage to proceed.</a></p>
        <p class="text-left" v-else><a href="#/login">Log in to proceed.</a></p>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'home',
  computed: {
    ...mapGetters({token: "token"})
  },
}
</script>

<style scoped>
img {
  max-width: 80%;
}
</style>
